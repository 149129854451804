const apiUrl = process.env.REACT_APP_API_URL;

export const createQuiz = async (token, formData) => {
    const response = await fetch(`${apiUrl}/admin/quiz`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
        credentials: 'include'
    });
    return response.json();
};

export const createChat = async (token, formData, chatId, chosenRole) => {
    const response = await fetch(`${apiUrl}/admin/chat/${chatId}/${chosenRole}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
        credentials: 'include'
    });
    return response.json();
};


export const fileUpload = async (token, formData) => {
    const response = await fetch(`${apiUrl}/admin/upload`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: formData,
        credentials: 'include'
    });
    return response.json();
};

export const fetchFilesFromKnowledge = async (token) => {
    const response = await fetch(`${apiUrl}/admin/files/`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        }

    });
    return response.json();
};

export const deleteFile = async (id) => {
    const response = await fetch(`${apiUrl}/api/files/${id}`, {
        method: 'DELETE',
    });
    return response.json();
};

export const downloadPodcast = async (token, id) => {
    return await fetch(`${apiUrl}/admin/download/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/octet-stream',
            'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
    });
};