import {fetchAuthSession, fetchUserAttributes} from "aws-amplify/auth";
import {Amplify} from "aws-amplify";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

export const checkUserAuthentication = async () => {
    const user = await fetchUserAttributes()
    const session = await fetchAuthSession();
    console.log('User is authenticated:', user);
    let group = session.tokens.idToken.payload['cognito:groups']
    console.log(group);
    return {username : user.email, group : group}
}