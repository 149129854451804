import React, {useEffect, useState} from 'react';
import {getJwtToken} from "../../authutils";
import {downloadPodcast} from "../../api";
import "../../styles/Container.css";

interface Podcast {
    id: number;
    title: string;
    description: string;
    url: string;
}

const PodcastList: React.FC = () => {
    const [podcasts, setPodcasts] = useState<Podcast[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // Simulate fetching podcast data from an API
    useEffect(() => {
        const fetchedPodcasts: Podcast[] = [
            {
                id: 1,
                title: 'EES Talk Episode 1',
                description: 'Introduction to the EES.',
                url: 'admin/podcast/ees-talk-ep1.mp3'
            }
        ];

        setPodcasts(fetchedPodcasts);
    }, []);

    const downloadFile = async (event: { preventDefault: () => void; }) => {
        try {
            event.preventDefault();
            setLoading(true);
            let token = await getJwtToken();
            const response = await downloadPodcast(token, "1");

            if (!response.ok) {
                throw new Error('Failed to download file');
            }
            const disposition = response.headers.get('content-disposition');
            const fileName = disposition?.split('filename=')[1] ?? 'EES-challenges.wav';

            // Create a Blob from the response data
            const blob = await response.blob();

            // Create a link element, trigger the download, and remove the element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
        setLoading(false);
    }


    return (
        <div className="content-container">
            <h1>Podcast Episodes</h1>
            <ul>
                {podcasts.map(podcast => (
                    <li key={podcast.id}>
                        {podcast.title}
                        <p>{podcast.description}</p>
                        {loading ? (
                            <p>loading</p>
                        ) : (
                            <a href={podcast.url} download>
                                <button onClick={downloadFile}>Download</button>
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PodcastList;