import React, {useState} from 'react';
import {Box, Button, TextField, Typography} from '@mui/material';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        // Login logic
    };

    return (
        <Box sx={{ padding: '2rem', maxWidth: '400px', margin: '0 auto', color: 'white' }}>
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                margin="normal"
                variant="outlined"
            />
            <Button variant="contained" color="secondary" onClick={handleLogin}>
                Login
            </Button>
        </Box>
    );
};

export default Login;