import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import theme from "./styles/theme";
import Header from './components/Header/Header';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './components/Login';
import {CssBaseline, ThemeProvider} from "@mui/material";
import FileUpload from "./pages/Knowledge/FileUpload";
import HomePage from "./pages/Home/HomePage";
import Footer from "./components/Footer/Footer";
import Chatbot from "./pages/Chatbot/Chatbot";
import {Amplify} from "aws-amplify";
import './amplify-custom.css';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import {Authenticator} from "@aws-amplify/ui-react";
import Quizbot from "./pages/Quiz/Quizbot";
import PodcastList from "./pages/Podcast/Podcast";

Amplify.configure(awsExports);


const App: React.FC = () => {
  return (
      <ThemeProvider theme={theme}>
          <CssBaseline />
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
            <Route path="/podcast" element={<Authenticator><PodcastList/></Authenticator>} />
            <Route path="/quiz" element={<Authenticator><Quizbot /></Authenticator>} />
            <Route path="/files" element={<Authenticator><FileUpload /></Authenticator>} />
            <Route path="/chat" element={<Authenticator><Chatbot /></Authenticator>} />
        </Routes>
        <Footer />
      </Router>
      </ThemeProvider>
  );
};

export default App;