import React, { useState } from 'react';
import './Roleslider.css';

type Role = 'Developer' | 'Legal' | 'Border Guard';

interface RoleSliderProps {
    onRoleChange: (role: Role) => void;
}

const roles: Role[] = ['Developer', 'Legal', 'Border Guard'];

const RoleSlider: React.FC<RoleSliderProps> = ({ onRoleChange }) => {
    const [selectedRole, setSelectedRole] = useState<Role>('Developer');

    const handleRoleChange = (role: Role) => {
        setSelectedRole(role);
        onRoleChange(role);
    };

    return (
        <div className="slider-container">
            <div className="slider-track">
                {roles.map((role) => (
                    <div
                        key={role}
                        className={`role-option ${role === selectedRole ? 'active' : ''}`}
                        onClick={() => handleRoleChange(role)}
                    >
                        {role}
                    </div>
                ))}
                <div className="slider-thumb" style={{ left: `${roles.indexOf(selectedRole) * 100}px` }} />
            </div>
        </div>
    );
};

export default RoleSlider;