import {fetchAuthSession} from "aws-amplify/auth";


export async function getJwtToken(): Promise<string | undefined> {
    try {
        const session = await fetchAuthSession();
        return session.tokens?.idToken?.toString()
    } catch (error) {
        console.error('Error retrieving token:', error);
        return undefined;
    }
}

