import React, {useState} from 'react';
import './ChatMessage.css';

interface ChatMessageProps {
    message: string;
    references: string[];
    sender: string;
    index: number;
}

const ChatMessage: React.FC<ChatMessageProps> = ({index, message, sender, references,}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div className={`chat-message ${sender === 'user' ? 'user-message' : 'bot-message'}`} key={index}>
            <div className="chat-logo" key={index}>
                {/* You can replace this with an actual image/icon */}
                <img src="/aify192.png" alt="logo" className="logo-image"/>
            </div>
            <div className="chat-content">
                <span>{message}</span>
                <span
                    className="info-icon"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
          ℹ️
        </span>
                {showTooltip && references && (
                    <div className="tooltip">
                        <ul>
                            {references.map((reference, index) => (
                                <li key={index}>{reference}</li> // Render each reference as a list item
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatMessage;