import React from "react";

const Contact: React.FC = () => (
    <section className="section" id="home">
        <div className="cards-container">

        </div>
    </section>
);

export default Contact;