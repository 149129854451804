import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 Aify Solutions. All rights reserved.</p>
            <div className="social-icons">
                <a href="https://twitter.com">Twitter</a>
                <a href="https://linkedin.com">LinkedIn</a>
                <a href="https://github.com">GitHub</a>
            </div>
        </footer>
    );
};

export default Footer;