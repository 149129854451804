// src/styles/theme.ts

import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00cba9', // Aurora-like green
        },
        secondary: {
            main: '#3b83bd', // Aurora-like blue
        },
        background: {
            default: '#111', // Aurora-like dark purple
        },
        text: {
            primary: '#ddd', // Text color
        },
    },
    typography: {
        fontFamily: ['Arial', 'sans-serif'].join(','), // Set a default font
    },
});

export default theme;