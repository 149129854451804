import React, {useEffect, useState} from 'react';
import {deleteFile, fetchFilesFromKnowledge, fileUpload} from "../../api";
import './FileUpload.css';
import '../../styles/Container.css';
import {getJwtToken} from "../../authutils";

const PdfUploader: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [files, setFiles] = useState<string[]>([]);

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        let token = await getJwtToken();
        const response = await fetchFilesFromKnowledge(token);
        setFiles(response);
    };

    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
            setUploadStatus(null);
        } else {
            setUploadStatus('Please select a valid PDF file.');
        }
    };

    // Handle file upload
    const handleFileUpload = async () => {
        if (!selectedFile) {
            setUploadStatus('No file selected.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            let token = await getJwtToken();
            const response = await fileUpload(token, formData)

            // Handle the response
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            await response.json();

            // Assuming the backend returns extracted text in 'text' field
            setUploadStatus('File uploaded successfully!');
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('Error uploading file.');
        }
    };

    const handleDelete = async (fileName: string) => {
        await deleteFile(fileName);
        fetchFiles();
    };

    return (
        <div className="content-container">
            <h1>Knowledge Base</h1>
            <label className="custom-file-upload">
                <input type="file" onChange={handleFileChange}/>
                Select File
            </label>
            <button onClick={handleFileUpload} disabled={!selectedFile}>Upload File</button>
            {uploadStatus && <p>{uploadStatus}</p>}
            <h2>Uploaded Files</h2>
            <ul>
                {files && files.map((file) => (
                    <li key={file}>
                        {file}
                        <button onClick={() => handleDelete(file)}>Remove</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PdfUploader;
