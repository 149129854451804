import React, {useEffect, useState} from 'react';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import './Chatbot.css';
import '../../styles/Container.css';
import {createChat} from "../../api";
import ChatMessage from "./ChatMessage";
import RoleSlider from "../Components/Roleslider";
import {getJwtToken} from "../../authutils"; // Import CSS for styling
import { v4 as uuidv4 } from 'uuid';

interface Message {
    text: string;
    sources: string[];
    sender: 'user' | 'bot';
}

type Role = 'Developer' | 'Legal' | 'Border Guard';

const Chatbot: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [chatId, setChatId] = useState<string>("");
    const [chosenRole, setChosenRole] = useState<Role>('Legal')

    useEffect(() => {
        // Generate and set a UUID when the component mounts
        const generatedChatId = uuidv4();
        setChatId(generatedChatId);
        console.log("Chat ID:", generatedChatId);
    }, []);

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!input.trim()) return;  // Prevent empty submissions

        const newMessages: Message[] = [...messages, {text: input, sources: [], sender: 'user'}];
        setMessages(newMessages);
        setInput('');
        setLoading(true);

        try {
            let token = await getJwtToken();
            const response = await createChat(token, input, chatId, chosenRole);  // Replace this with actual API call
            setMessages((prev) => [...prev, {text: response.message, sources: response.sources, sender: 'bot'}]);
        } catch (error) {
            console.error('Error:', error);
            setMessages((prev) => [...prev, {text: "Sorry, something went wrong.", sources: [], sender: 'bot'}]);
        } finally {
            setLoading(false);
        }
    };

    const handleRoleChange = (role: Role) => {
        console.log(`Selected Role: ${role}`);
        setChosenRole(role)
        // Use the selected role to tailor the chatbot response
    };

    return (
        <div className="content-container">
            <RoleSlider onRoleChange={handleRoleChange} />
            <div className="chat-box">
                {messages.map((message, index) => (
                    <ChatMessage
                        index={index}
                        message={message.text}
                        sender={message.sender}
                        references={message.sources}
                    />
                ))}
            </div>

            <form className="chat-input-box" onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={input}
                    onChange={handleInputChange}
                    disabled={loading}
                    placeholder="Type your message..."
                />
                <button type="submit" disabled={loading || !input.trim()}>
                    {loading ? <AiOutlineLoading3Quarters className="spinner"/> : 'Send'}
                </button>
            </form>
        </div>
    );
};

export default Chatbot;