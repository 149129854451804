import React from "react";
import "./HomePage.css";
import {Link} from "react-router-dom";

const HomePage: React.FC = () => {
    return (
        <div className="home-page">


            <section className="hero-section">
                <div className="hero-content">
                    <h2>Empowering Conversations with Intelligent Data</h2>
                    <p>Our AI-powered chat system leverages the latest in RAG technology to provide meaningful and contextually accurate answers by retrieving relevant files.</p>
                    <Link to="/chat" className="cta-button">Start Chatting</Link>
                </div>
            </section>

            <section className="features-section">
                <div className="feature-card">
                    <h3>AI Chat</h3>
                    <p>Engage in real-time conversations with our intelligent chatbot to get instant, accurate responses.</p>
                </div>
                <div className="feature-card">
                    <h3>Document Retrieval</h3>
                    <p>Access important documents directly within the chat interface for seamless integration of data and conversation.</p>
                </div>
                <div className="feature-card">
                    <h3>Simple and Intuitive</h3>
                    <p>Designed with a clean and user-friendly interface for a smooth experience on all devices.</p>
                </div>
            </section>

        </div>
    );
};

export default HomePage;
