import React, {useEffect, useState} from 'react';
import './Header.css';
import {checkUserAuthentication} from "../../amplify-api";
import {Link} from "react-router-dom";

const Header: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        async function checkAuth() {
            try {
                const currentUser = await checkUserAuthentication();
                setIsAuthenticated(true);
                setUser(currentUser);
            } catch (error) {
                setIsAuthenticated(false);
            }
        }

        checkAuth();
    }, []);

    return (
        <header className="header">
            <div className="logo">
                <h1>Distilbert AI</h1>
            </div>
            <nav className="nav">
                <ul>
                    <Link to="/">Home</Link>
                    <Link to="/chat">Chat</Link>
                    <Link to="/quiz">Quiz</Link>
                    <Link to="/podcast">Podcast</Link>
                    <Link to="/files">Files</Link>
                    <Link to="/contact">Contact</Link>
                </ul>
            </nav>
            <div className="login-info">
                {isAuthenticated ? (
                    <div className="user-info">
                        <span>{user.username} ({user.group})</span>
                        <Link to="/logout" className="logout-link">Logout</Link>
                    </div>
                ) : (
                    <Link to="/login" className="login-link">Login</Link>
                )}
            </div>
        </header>
    );
};

export default Header;