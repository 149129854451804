import React, {useEffect, useState} from "react";
import {getJwtToken} from "../../authutils";
import {createQuiz} from "../../api";
import './Quiz.css'
import {Choice, QuizData} from "./QuizData";

const Quizbot: React.FC = () => {
    const [quizData, setQuizData] = useState<QuizData>({
        choices: [],
        correctAnswer: 'A',
        question:  '',
        explanation: '',

    });
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedChoice, setSelectedChoice] = useState<Choice | null>(null);
    const [isAnswered, setIsAnswered] = useState(false);

    // Handle form submission
    // Fetch questions from AI/RAG system (simulated in this case)
    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        setLoading(true);
        let token = await getJwtToken();
        const response = await createQuiz(token, "create a quiz based on a random section of the context");
        setQuizData(response);
        setLoading(false);
    };

    const handleSelect = (choice: Choice) => {
        if (!isAnswered) {
            setSelectedChoice(choice);
        }
    };

    const handleSubmit = () => {
        if (selectedChoice) {
            setIsAnswered(true);
        }
    };

    const isCorrect = (option: Choice) => {
        return option && option.label === quizData.correctAnswer;
    };

    const handleNextQuestion = () => {
        // Move to the next question or restart
        fetchQuestions();
        setSelectedChoice(null);
        setIsAnswered(false);
    };

    return (
        <div className="content-container">
            {loading ? (
                <div className="chat-loader">
                    loading...
                </div>
            ) : (
            <div className="inner-container">
                <h2>{quizData.question}</h2>
                <div>
                    {quizData.choices.map((option, index) => (
                        <div
                            key={index}
                            className={`option ${selectedChoice === option ? "selected" : ""} ${
                                isAnswered && isCorrect(option)
                                    ? "correct"
                                    : isAnswered && selectedChoice === option && !isCorrect(option)
                                        ? "incorrect"
                                        : ""
                            }`}
                            onClick={() => handleSelect(option)}
                        >
                            {option.label} : {option.choice}
                        </div>
                    ))}
                </div>
                {!isAnswered ? (
                    <button className="quiz-button" onClick={handleSubmit} disabled={!selectedChoice}>
                        Submit
                    </button>
                ) : (
                    <>
                        <div className={`explanation ${isAnswered ? "visible" : ""}`}>
                            {isCorrect(selectedChoice!) ? (
                                <p>Correct! {quizData.explanation}</p>
                            ) : (
                                <p>Incorrect. {quizData.explanation}</p>
                            )}
                        </div>
                        <button onClick={handleNextQuestion}>
                            Next Question
                        </button>
                    </>
                )}
            </div>
            )}
        </div>
    );
};

export default Quizbot;